<template>
  <div>
    <b-card>
      <b-form-group
        label="Jumlah Top up"
        label-for="topUp"
      >
        <b-form-input
          v-model="topUp"
          placeholder="Masukkan Jumlah Uang"
        />
      </b-form-group>
      <b-button
        variant="primary"
        class="btn-icon"
        @click="postman()"
      >
        Tambah
      </b-button>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import {
  BCard,
  BButton,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    axios,
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      info: '',
      topUp: '',

    }
  },
  methods: {
    postman() {
      const options = {
        url: 'https://us-central1-mecare-life.cloudfunctions.net/prosesTopUp',
        method: 'POST',
        data: {
          api_key: 'UmRTOGQ1ckU3QUY4c3VidnBCaFYxbzI4djR5MEo2YVFud3B2NTNrUkpZRjFCSG76e115db7cdVZT3Z6MU51YmZhMnAy5b',
          account_id: 12,
          module: 'bri',
          account_name: 'PT Amanah Karya Indonesia',
          account_number: null,
          balance: 452030,
          data_mutasi: [{
            transaction_date: '2018-08-10 00:00:00',
            description: 'TRANSFER EDC  MUHLISIN        TO PT AMANAH KARYA',
            type: 'CR',
            amount: this.topUp,
            balance: 427099,
          }],
        },
      }
      this.$axios(options).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Top Up Berhasil DiTambahkan',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Top Up Tidak Bisa DiTambahkan',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        })
    },
  },
}
</script>

<style>

</style>
